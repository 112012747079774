<template>
  <div style="display: flex">
    <click-to-edit
      v-model="scope.row.weight"
      style="width: 100%"
      :disabled="!isFieldAvailableForEdit(scope.row, 'weight')"
      placeholder=""
      digits-only
      @input="saveElementPropChange(scope.row, 'weight')"
    ></click-to-edit>

    <el-popconfirm
      v-if="!scope.row.skip_weight_validation && $canAndRulesPass(scope.row, 'card_element.disable_weight_check')"
      style="margin-left: 10px"
      confirm-button-text='Да'
      cancel-button-text='нет'
      icon="el-icon-question"
      title="Вы уверены, что хотите отключить ограничение веса? Возврат ограничения будет недоступен."
      @confirm="disableWeightCheck"
    >
      <el-button
        slot="reference"
        style="color: black"
        type="text"
        size="mini"
        icon="fas fa-filter"
      ></el-button>
    </el-popconfirm>

    <el-tooltip
      v-if="scope.row.skip_weight_validation"
      content="Ограничение веса отключено."
      placement="top"
      :open-delay="500"
    >
      <div style="display: inline-block; margin-left: 10px">
        <i class="fas fa-exclamation-circle"></i>
      </div>

    </el-tooltip>

  </div>
</template>

<script>


import ClickToEdit from "@/components/ClickToEdit.vue";
import {ElementPropTableColumn} from "@/mixins/elementPropTableColumn";

export default {
  name: 'element-weight-with-disable-table-column',
  components: {ClickToEdit},
  mixins: [ElementPropTableColumn],

  props: {},
  data() {
    return {}
  },

  methods: {
    disableWeightCheck(){
      this.scope.row.skip_weight_validation = true;
      this.saveElementPropChange(this.scope.row, 'skip_weight_validation');
    },
  }
}

</script>

<style>

</style>
